import { render, staticRenderFns } from "./ProblemAdmin.vue?vue&type=template&id=03101415&scoped=true"
import script from "./ProblemAdmin.vue?vue&type=script&lang=js"
export * from "./ProblemAdmin.vue?vue&type=script&lang=js"
import style0 from "./ProblemAdmin.vue?vue&type=style&index=0&id=03101415&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03101415",
  null
  
)

export default component.exports